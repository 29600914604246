<template>
  <section :class="['bleed-screen', bgClassList]">
    <div class="space-y-8 px-6 py-12 sm:space-y-[60px] sm:px-30 sm:py-20">
      <h2 v-if="$slots.heading" class="display-3xl sm:display-5.5xl">
        <slot name="heading" />
      </h2>

      <ul
        :class="[
          props.justifyInject ? props.justifyInject : 'justify-between',
          'flex flex-wrap gap-y-6 sm:gap-y-16',
        ]"
      >
        <slot />
      </ul>

      <div v-if="$slots.text" class="max-w-4xl text-base md:text-xl">
        <slot name="text" />
      </div>

      <div
        v-if="$slots.buttons"
        class="!mt-10 flex flex-col gap-6 md:flex-row md:justify-start"
      >
        <slot name="buttons" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    bg?: "grey" | "blue" | "white" | "green";
    withPatternLine?: boolean;
    justifyInject?: string;
  }>(),
  {
    bg: "white",
    justifyInject: "",
  },
);

const bgStyles = {
  blue: "bg-blue-700 text-white",
  green: "bg-green-400 text-black",
  grey: "bg-neutral-200 text-black",
  white: "bg-white text-black",
};

const bgClassList = computed(() => {
  return bgStyles[props.bg];
});

provide("bg", props.bg);
</script>
