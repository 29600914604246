<template>
  <li
    class="flex basis-full flex-row items-stretch border-l border-solid border-black py-1 pl-4 sm:basis-[46%] md:pl-6"
  >
    <div>
      <div class="display-2.5xl md:display-5xl whitespace-nowrap">
        {{ props.number }}
      </div>

      <div
        v-if="$slots.default"
        class="mt-2 text-base leading-4 md:mt-3 md:text-2xl md:leading-6"
      >
        <slot />
      </div>
    </div>

    <div
      v-if="props.slashNumber"
      :class="[
        'mx-4 h-auto w-px rotate-[20deg] sm:mx-8',
        sectionBackground === 'blue' ? 'bg-white' : 'bg-black',
      ]"
    />

    <div v-if="props.slashNumber">
      <div class="display-2.5xl md:display-5xl whitespace-nowrap">
        {{ props.slashNumber }}
      </div>

      <div
        v-if="$slots.slash"
        class="mt-2 text-base leading-4 md:mt-3 md:text-2xl md:leading-6"
      >
        <slot name="slash" />
      </div>
    </div>
  </li>
</template>
<script setup lang="ts">
const props = defineProps<{ number: string; slashNumber?: string }>();
const sectionBackground = inject("bg");
</script>
